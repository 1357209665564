import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { toPng } from "html-to-image";
import axios from "axios";

function LinkIDCard() {
  const { eventId, uniqueId } = useParams();
  const [participant, setParticipant] = useState(null);
  const [design, setDesign] = useState(null); // State for design data
  const [isDownloading, setIsDownloading] = useState(false);
  const idCardRef = useRef(null);
  const [elementStyles, setElementStyles] = useState({
    profilePicture: { bottom: 160, size: 170 },
    name: { top: 200, fontSize: 20, color: "white" },
    institute: { bottom: 130, fontSize: 18, color: "white" },
    designation: { bottom: 107, fontSize: 16, color: "black" },
    qrCode: { bottom: 15 },
    participantId: { bottom: 1, fontSize: 12, color: "black" },
  });
  const [globalVisibility, setGlobalVisibility] = useState({
    name: true,
    profilePicture: true,
    institute: true,
    designation: true,
    qrCode: true,
    participantId: true,
  });
  // Fetch participant data
  useEffect(() => {
    console.log("Fetching participant data...");
    fetch(
      `${process.env.REACT_APP_API_URL}/api/participants/get-participant/${eventId}/${uniqueId}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch participant data");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Participant data:", data);
        setParticipant(data);
      })
      .catch((error) => {
        console.error("Error fetching participant:", error);
      });
  }, [eventId, uniqueId]);
  
  useEffect(() => {
    console.log("Fetching design settings...");
    fetchDesignSettings();
  }, [eventId]);
  
  const fetchDesignSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/participants/design/${eventId}`
      );
      console.log("Design data:", response.data);
      if (response.data && response.data.elementStyles) {
        setElementStyles(response.data.elementStyles);
      }
      if (response.data && response.data.visibility) {
        setGlobalVisibility(response.data.visibility);
      }
      setDesign(response.data); // Ensure design is set here
    } catch (error) {
      console.error("Error fetching design settings:", error);
    }
  };
  
  if (!participant || !design) {
    console.log("Waiting for data...");
    return <div>Loading...</div>;
  }
  
  // Download with background
  const downloadImage = () => {
    const element = idCardRef.current;
    if (!element) return;

    setIsDownloading(true);
    toPng(element, {
      cacheBust: true,
      quality: 1,
      pixelRatio: 3,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "id-participant.png";
        link.click();
      })
      .catch((error) => console.error("Could not download the image", error))
      .finally(() => setIsDownloading(false));
  };

  // Download without background
  const downloadImageWithoutBackground = () => {
    const element = idCardRef.current;
    if (!element) return;

    const imgElement = element.querySelector("img");
    const originalDisplay = imgElement ? imgElement.style.display : "block";
    if (imgElement) imgElement.style.display = "none";

    setIsDownloading(true);
    toPng(element, {
      cacheBust: true,
      quality: 1,
      pixelRatio: 3,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "id-card-no-background.png";
        link.click();
      })
      .catch((error) => console.error("Could not download the image", error))
      .finally(() => {
        if (imgElement) imgElement.style.display = originalDisplay;
        setIsDownloading(false);
      });
  };

  if (!participant || !design) {
    return <div>Loading...</div>;
  }

  // Helper function to get styles from design settings
  const getStyle = (element) => design.elementStyles[element] || {};
  const defaultElementStyles = {
    profilePicture: { bottom: 160, size: 170 },
    name: { top: 200, fontSize: 20, color: "white" },
    institute: { bottom: 130, fontSize: 18, color: "white" },
    designation: { bottom: 107, fontSize: 16, color: "black" },
    qrCode: { bottom: 15 },
    participantId: { bottom: 1, fontSize: 12, color: "black" },
  };
  const styles = elementStyles || defaultElementStyles;
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-background">
    
      <div
        ref={idCardRef}
        className="relative mb-5 rounded-[1px] h-[610px] w-[430px]"
      >
        <div className="relative z-10 h-full text-white">
          <div className="absolute inset-0">
            {participant.backgroundImage && (
              <img
                src={participant.backgroundImage}
                alt=""
                className="w-full h-full object-cover"
              />
            )}
          </div>
          <div className="relative h-full flex flex-col justify-between p-4">
            {globalVisibility.profilePicture && participant.profilePicture && (
              <div
                style={{
                  bottom: `${styles.profilePicture.bottom}px`,
                }}
                className="absolute bottom-[160px] left-[50%] transform -translate-x-1/2"
              >
                <img
                  style={{
                    objectFit: "cover",
                    width: `${styles.profilePicture.size}px`,
                    height: `${styles.profilePicture.size}px`,
                  }}
                  src={participant.profilePicture}
                  alt="Profile"
                  className="w-[170px] h-[170px] rounded-[2px]"
                />
              </div>
            )}
            {globalVisibility.name && (participant?.firstName || participant?.lastName) && (
              <h2
                style={{
                  top: `${styles.name.top}px`,
                  fontSize: `${styles.name.fontSize}px`,
                  color: styles.name.color,
                }}
                className="absolute top-[200px] uppercase left-[50%] transform -translate-x-1/2 text-[20px] font-bold text-center mt-2 w-full text-white"
              >
                {participant.firstName} {participant.lastName || ""}{" "}
                {/* Ensure there's no extra space if lastName is missing */}
              </h2>
            )}

            {globalVisibility.institute && participant.institute && (
              <p
                style={{
                  bottom: `${styles.institute.bottom}px`,
                  fontSize: `${styles.institute.fontSize}px`,
                  color: styles.institute.color,
                }}
                className="absolute bottom-[130px] left-0 right-0 w-full whitespace-nowrap text-lg font-semibold text-center text-white mt-1"
              >
                {participant.institute
                  .toUpperCase()}
              </p>
            )}
            {globalVisibility.designation && participant.designation && (
              <p
                style={{
                  bottom: `${styles.designation.bottom}px`,
                  fontSize: `${styles.designation.fontSize}px`,
                  color: styles.designation.color,
                }}
                className="absolute bottom-[107px] left-[50%] transform -translate-x-1/2 text-md font-bold text-center text-black"
              >
                {participant.designation}
              </p>
            )}
           
           
          </div>
        </div>
      </div>
      {/* Download buttons */}
      <div className="flex justify-between gap-4">
        <button
          onClick={downloadImage}
          className="border text-black flex p-3 bg-gray-300 font-semibold rounded gap-2"
        >
          Download ID Card With Background
          {isDownloading && (
            <svg
              className="animate-spin h-5 w-5 text-black"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
        <button
          onClick={downloadImageWithoutBackground}
          className="border text-black p-3 bg-gray-300 hover:bg-gray-500 font-semibold rounded"
        >
          Download ID Card Without Background
          {isDownloading && (
            <svg
              className="animate-spin h-5 w-5 text-black"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
      </div>
    </div>
  );
}

export default LinkIDCard;
